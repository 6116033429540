<template>
    <div class="content">
        <!-- <div class="select">
            <div class="select-box">
                <div
                    class="sb-list"
                    :class="$route.path == item.path ? 'active' : ''"
                    @click="open(item.path)"
                    v-for="(item, i) in nav"
                    :key="i"
                >
                    <div class="jiao-box">
                        <div class="jiao"></div>
                    </div>
                    <div class="icon-box"></div>
                    <p>{{ item.name }}</p>
                </div>
            </div>
        </div> -->
        <div class="zhen-box">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nav: [
                {
                    name: "账号列表",
                    path: "/home/matrix/list",
                    img: require("@/assets/images/juzhen/list_1.png"),
                    select_img: require("@/assets/images/juzhen/list_2.png"),
                },
                {
                    name: "账号分组",
                    path: "/home/matrix/gruop",
                    img: require("@/assets/images/juzhen/user_1.png"),
                    select_img: require("@/assets/images/juzhen/user_2.png"),
                },
                {
                    name: "任务列表",
                    path: "/home/matrix/task",
                    img: require("@/assets/images/juzhen/fa_1.png"),
                    select_img: require("@/assets/images/juzhen/fa_2.png"),
                },
            ],
        };
    },
    methods: {
        open(path) {
            this.$router.push(path);
        },
    },
};
</script>


<style lang="less" scoped>
.content {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.select {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 20px;
    color: #837f7f;
    &-box {
        width: 100%;
        margin: auto;
        display: flex;

        .sb-list {
            background-color: #fff;
            border-radius: 5px;
            margin-right: 30px;
            position: relative;
            & > .jiao-box {
                content: "";
                position: absolute;
                bottom: -8px;
                left: 63px;
                transform: rotate(67deg);
                display: none;
                .jiao {
                    width: 15px;
                    height: 11px;
                    border-bottom: 1px solid #fc6c98;
                    border-right: 1px solid #fc6c98;
                    transform: skew(44deg, 0deg);
                    background: #fff;
                }
            }
            &.active {
                border: 1px solid #fc6c98;
                box-shadow: 0px 6px 18px 0px rgba(255, 51, 113, 0.102);
                & > .jiao-box {
                    display: block;
                }
            }
            & .icon-box {
                width: 40px;
                height: 40px;
                background-image: url(../../assets/images/juzhen/auth_icon.png);
                background-size: 40px;
            }
            &:nth-child(1) {
                .icon-box {
                    background-position-y: -80px;
                }
                p {
                    color: #ef5641;
                }
            }
            &:nth-child(2) {
                p {
                    color: #f63972;
                }
            }
            &:nth-child(3) {
                .icon-box {
                    background-position-y: -40px;
                    // background-position-y: -80px;
                }
                p {
                    color: #ae44ff;
                }
            }
            width: 140px;
            height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            p {
                line-height: 38px;
                font-size: 12px;
            }
        }
        // .active {
        //     color: #349ffd;
        //     background: linear-gradient(
        //         180deg,
        //         #ffffff 0%,
        //         rgba(255, 133, 153, 0.2)
        //     );
        //     border-radius: 20px;
        //     p {
        //         color: #ff8599;
        //     }
        // }
    }
}
.zhen-box {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    flex: 1;
    height: 0;
    // overflow: auto;
}
</style>